import React from 'react';
import Fade from 'react-reveal/Fade';
import './work.css';
import DecoWork from '../../assets/deco-about.svg'
import Work1 from '../../assets/acs-mobile.png'
import Work2 from '../../assets/azuream.png'
import Work3 from '../../assets/stellar.png'
import Work4 from '../../assets/interface-dulin1.png'
import Work5 from '../../assets/interface-peintures-salle-royale-invalides.png'
import Work6 from '../../assets/interface-forces-speciales-musee-de-larmee.png'
import Work7 from '../../assets/application-jeu-musee-de-larmee-invalides.png'
import Work8 from '../../assets/developpement-front-end-site-internet.png'

const Work = () => {
    return(
        <section id="work">
             <div className="container work__container">                 
                    <img className="deco-work" src={DecoWork} alt="décoration section" />
                    <div className="sec-title-work">                
                        <div className="line-title-work"></div>
                        <h3>Travail</h3>                                    
                    </div>
                    <Fade>
                        <div className="inside">
                            <article className="portfolio__item">{/*Un item*/}
                                <div className="container-item">
                                    <div className="portfolio__item-image">
                                        <img className="thumbnail" src={Work1} alt="Application mobile de musique"/>
                                    </div>
                                    <h3>Application Musique</h3>
                                </div>                            
                                <a href="https://github.com/adelinecodeuronline/ACS-Music" className="btn btn-primary btn-work" >Voir le projet</a>
                            </article>{/*Fin item*/}

                            <article className="portfolio__item">
                                <div className="container-item">
                                    <div className="portfolio__item-image">                               
                                        <img className="thumbnail" src={Work2} alt="Template page web tourisme spatial"/>
                                    </div>
                                    <h3>Template de site internet</h3>
                                </div>
                                <a href="https://github.com/adelinecodeuronline/azuream" className="btn btn-work" >Voir le projet</a>                           
                            </article>

                            <article className="portfolio__item">
                                <div className="container-item">
                                    <div className="portfolio__item-image">
                                        <img className="thumbnail" src={Work3} alt="Site web API films"/>
                                    </div>
                                    <h3>Application web</h3>
                                </div>
                                <a href="https://kemister.fr/Stellar" className="btn btn-work" >Voir le projet</a>                            
                            </article>

                            <article className="portfolio__item">
                                <div className="container-item">
                                    <div className="portfolio__item-image">
                                        <img className="thumbnail" src={Work4} alt="Interface musée"/>
                                    </div>
                                    <h3>Interface pour le musée de l'Armée (Hôtel national des Invalides)</h3>
                                </div>
                                <a href="https://kemister.fr/interface-musee" className="btn btn-work" >Voir le projet</a>                            
                            </article>

                            <article className="portfolio__item">
                                <div className="container-item">
                                    <div className="portfolio__item-image">
                                        <img className="thumbnail" src={Work5} alt="Interface musée"/>
                                    </div>
                                    <h3>Interface tactile de peintures murales (Hôtel national des Invalides)</h3>
                                </div>
                                <a href="https://kemister.fr/musee-interactif-peintures" className="btn btn-work" >Voir le projet</a>                            
                            </article>

                            <article className="portfolio__item">
                                <div className="container-item">
                                    <div className="portfolio__item-image">
                                        <img className="thumbnail" src={Work6} alt="Interface musée"/>
                                    </div>
                                    <h3>Dispositifs pour exposition "Forces Spéciales" (Hôtel national des Invalides)</h3>
                                </div>
                                <a href="https://kemister.fr/dalle-tactile-exposition" className="btn btn-work" >Voir le projet</a>                            
                            </article>

                            <article className="portfolio__item">
                                <div className="container-item">
                                    <div className="portfolio__item-image">
                                        <img className="thumbnail" src={Work7} alt="Interface musée"/>
                                    </div>
                                    <h3>Applications pour tablette - jeune public (Hôtel national des Invalides)</h3>
                                </div>
                                <a href="https://kemister.fr/application-interactive-musee" className="btn btn-work" >Voir le projet</a>                            
                            </article>

                            <article className="portfolio__item">
                                <div className="container-item">
                                    <div className="portfolio__item-image">
                                        <img className="thumbnail" src={Work8} alt="Interface musée"/>
                                    </div>
                                    <h3>Prototype de site internet (développement et design)</h3>
                                </div>
                                <a href="https://kemister.fr/titre" className="btn btn-work" >Voir le projet</a>                            
                            </article>
                        </div>
                    </Fade>
             </div>          
             
        </section>
    )
}

export default Work