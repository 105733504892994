import React from 'react'
/*import CV from '../../assets/cv_adeline_bonnard_AB.pdf'*/

const CTA = () => {
    return(
       <div className="cta">
           {/*<a href={CV} download className="btn">Télécharger le CV</a>*/}
           <a href="#contact" className="btn">Collaborons !</a>
       </div>
    )
}

export default CTA